import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import {
  FaRegUserCircle, FaUserPlus, FaHome, FaNewspaper,
} from 'react-icons/fa';
import Dropdown from 'react-bootstrap/Dropdown';
import Link from 'next/link';

import { useUserContext } from '@/lib/context/useUserContext';
import { Spinner, Row } from 'react-bootstrap';
import Image from 'next/image';

function LogInHeader({ notapproved, location }) {
  const notificationCount = 0;
  const { user, isLoading, signOut } = useUserContext();

  if (isLoading) return <Spinner />;

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="login-dropdown"
    >
      {children}
      <div style={{ display: 'inline-block', fontSize: '12px' }}>&#x25bc;</div>
    </a>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({
      children, style, className, 'aria-labelledby': labeledBy,
    }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled" id="login-dropdown-ul">
            {React.Children.toArray(children).filter(
              (child) => !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

  const dropdown = (
    <Nav.Item
      id="dropdown"
    >
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <FaRegUserCircle />
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} style={{ backgroundColor: 'white' }} alignRight>
          <Dropdown.Item href="/dashboard/match" eventKey="1" style={{ backgroundColor: 'white' }}>
            <Link href="/dashboard/match">
              <Nav.Link href="/dashboard/match">
                Signed in as
                {' '}
                <br />
                <b>{user.name}</b>
              </Nav.Link>
            </Link>
          </Dropdown.Item>
          <Dropdown.Divider style={{ margin: 0 }} />

          {/* <Dropdown.Item href="/dashboard/settings"
          eventKey="1" style={{ backgroundColor: 'white', paddingTop: 0 }}>
            <Link href="/dashboard/settings">
              <Nav.Link href="/dashboard/settings">
                Settings
              </Nav.Link>
            </Link>
          </Dropdown.Item>
          <Dropdown.Divider style={{ margin: 0 }} /> */}
          {/* <Dropdown.Item href="/dashboard/settings" eventKey="1" style={{ backgroundColor: 'white', paddingTop: 0 }}>
            <Nav.Link href="/whatsnew" target="_blank">
              What's New
            </Nav.Link>
          </Dropdown.Item> */}
          <Dropdown.Divider style={{ margin: 0 }} />
          {/* <Dropdown.Item href="/dashboard/settings" eventKey="1" style={{ backgroundColor: 'white', paddingTop: 0 }}>
            <Nav.Link href="https://forms.gle/nMRKMgxkRAw92kwD7" target="_blank">
              Give feedback
            </Nav.Link>
          </Dropdown.Item> */}
          <Dropdown.Divider style={{ margin: 0 }} />

          <Dropdown.Item href="/dashboard/settings" eventKey="1" style={{ backgroundColor: 'white', paddingTop: 0 }}>
            <Nav.Link href="mailto:info@purposepals.org?subject=Request for Technical Support" target="_blank">
              Find support
            </Nav.Link>
          </Dropdown.Item>
          <Dropdown.Divider style={{ margin: 0 }} />

          <Dropdown.Item href="" eventKey="1" style={{ backgroundColor: 'white', paddingTop: 0 }} onClick={signOut}>
            <Nav.Link>
              Log out
            </Nav.Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Nav.Item>
  );
  // end of dropdown component

  if (notapproved) {
    return (

      <div className="dashboard-header-wrapper">
        <Container>
          <Nav
            className="dashboard-header"
            activeKey={location}
          >
            <Navbar.Brand href="/dashboard/notapproved">
              <Image
                src="/images/combined_logo.png"
                alt="Purpose Pals + Big & Mini"
                width={216}
                height={80}
              />
            </Navbar.Brand>
            <div id="mobile-dropdown">{dropdown}</div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <div className="float-right">
              <span className="desktop-navbar">{dropdown}</span>
            </div>
          </Nav>
        </Container>

      </div>
    );
  }

  // what's returned if user is logged in
  return (
    <div className="dashboard-header-wrapper">
      <Container>

        <Nav
          className="dashboard-header"
          activeKey={location}
        >
          <Link href="/dashboard/match">
            <Navbar.Brand href="/dashboard/match">
              <Image
                src="/images/combined_logo.png"
                alt="Purpose Pals + Big & Mini"
                width={216}
                height={80}
              />
            </Navbar.Brand>
          </Link>
          <div id="mobile-dropdown">{dropdown}</div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          {/* <Navbar.Collapse id="responsive-navbar-nav"
        // className="justify-content-end"
        > */}
          <div className="float-right">
            <span className="desktop-navbar">{dropdown}</span>
            {/* <Nav.Item id="logout-mobile">
              <Nav.Link href="" onClick={logout}>Log out</Nav.Link>
            </Nav.Item> */}
            {/* <Nav.Item>
              <Link href="/dashboard/invite">
                <Nav.Link eventKey="link-3" href="/dashboard/invite">
                  <FaUserPlus />
                  {' '}
                  Invite Friends
                </Nav.Link>
              </Link>
            </Nav.Item> */}
            {/* <Nav.Item>
              <Link href="/dashboard/explore">
                <Nav.Link eventKey="link-1" href="/dashboard/explore">
                  <FaNewspaper />
                  {' '}
                  Explore
                </Nav.Link>
              </Link>
            </Nav.Item> */}
            <Nav.Item>
              <Link href="/dashboard/match">
                <Nav.Link eventKey="link-2" href="/dashboard/match">
                  <FaHome />
                  {' '}
                  Home
                  {notificationCount === 0 ? null : <div className="notification">{notificationCount}</div>}
                </Nav.Link>
              </Link>
            </Nav.Item>

          </div>
          {/* </Navbar.Collapse> */}
        </Nav>
      </Container>

    </div>
  );
}

export default LogInHeader;
